import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; 
import FlexBox from "../../styledcomponents/FlexBox";
import CTAButton from "../../styledcomponents/CtaButton";
import useMobile from "../../uses/UseMobile";
import BannerH1 from "../../styledcomponents/text/BannerH1";

const CTABannerButton = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const navigate = useNavigate();


  return (
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="48px 16px"
      width="100%"
      minWidth="375px"
      height={isMobile ? "300px" : "267px"}
      background="#EEEFFE"
      style={{
        backgroundImage: isMobile
          ? "url('/assets/images/features/BG_Banner(375).png')"
          : window.innerWidth >= 1280
          ? "url('/assets/images/features/BG_Banner(1920).png')"
          : "url('/assets/images/features/BG_Banner(1100).png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "all 0.3s",
        textAlign: "center",
        gap: "10px"
      }}
    >
      <BannerH1 whiteSpace="normal">
        {t("banner_cta.description2")}
      </BannerH1>

      <CTAButton
        style={{
          marginTop: "24px",
          minWidth: isMobile ? "172px" : "185px",
          padding: "12px 24px"
        }}
        onClick={() => navigate("/login")} 
      >
        <span
          style={{
            fontWeight: 600,
            textAlign: "center",
            whiteSpace: "nowrap",
            fontSize: isMobile ? "18px" : "20px",
            lineHeight: "140%",
            letterSpacing: isMobile ? "-0.18px" : "-0.2px"
          }}
        >
          {t("startForFree")}
        </span>
      </CTAButton>
    </FlexBox>
  );
};

export default CTABannerButton;