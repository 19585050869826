import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H1 = styled(Text)`
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  font-size: ${reactiveValueInContent("54px", "40px")};
  letter-spacing: -0.48px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    line-height: 140%;
  }
`;

export default H1;