import React, { useState } from "react";
import { Drawer, AppBar, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "../modules";
import Image from "../styledcomponents/Image";
import FlexBox from "../styledcomponents/FlexBox";
import LanguageMenu from "./LanguageMenu";
import CTAButton from "../styledcomponents/CtaButton";
import { useTranslation } from "react-i18next";
import MobileDropdownMenu from "./MobileDropdownMenu";
import { LocalStorageManager } from "../LocalStorageManager";
import ReactGA from "react-ga4";
import { Logger } from "../log/Logger";
import { Users } from "../modules/Users";
import { KeyboardUtils } from "../utils/KeyboardUtils";


type NavItem = {
    key: string;
    labelKey?: string;
    path?: string;
    external?: boolean;
    isDropdown?: boolean;
  };

  
  const navItems: NavItem[] = [
    { key: "home", labelKey: "home", path: "/" },
    { key: "about", labelKey: "about", path: "https://about.thirdworks.co.kr/", external: true },
    { key: "dashboard", isDropdown: true },
    { key: "sourcing", labelKey: "sourcing", path: "https://sourcing.third.works/" },
    { key: "inquiry", labelKey: "sourcing_inquiry", path: "/sourcing-inquiry" },
  ];

const MobileAppBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const { signedIn } = useSelector((state: RootState) => state.usersReducer, shallowEqual);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const handleNavClick = (item: any) => {
    if (item.external) {
      window.location.href = item.path;
    } else {
      navigate(item.path);
    }
    toggleDrawer();
  };

  const componentName = "MobileAppBar"; 


function handleLoginOrLogout(event: React.MouseEvent<any>) {
  if (signedIn) {
    ReactGA.event({ category: "mobile_app_bar", action: "logout" });
    Logger.info(componentName, `logout. userId: ${LocalStorageManager.getUserId()}`);
    dispatch(Users.logout()); // 이제 에러 없이 작동!
    navigate("/");
  } else {
    ReactGA.event({ category: "mobile_app_bar", action: "login" });
    toggleDrawer();
    if (KeyboardUtils.isNewWindowTriggerKey(event)) window.open("/login");
    else navigate("/login");
  }
}

  return (
    
    <>
      {/* Top AppBar */}
      <AppBar
        elevation={0}
        style={{ height: 64, backgroundColor: "#fff", borderBottom: "1px solid #e5e7eb" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between", padding: "0 16px" }}>
          <Image
            width="130px"
            height="14px"
            src="/assets/images/logo.svg"
            alt="Thirdworks Logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          <FlexBox alignItems="center" style={{ gap: "16px" }}>
            <LanguageMenu />
            <Image
              width="24px"
              height="24px"
              src="/assets/icons/hamburgerMenu.svg"
              alt="Menu"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer}
            />
          </FlexBox>
        </Toolbar>
      </AppBar>

      <Toolbar id="back-to-top-anchor" />

      {/* Drawer */}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "1099px",
            margin: "0 auto",
            padding: 0,
          },
        }}
      >
        <FlexBox flexDirection="column" style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
          {/* Drawer Header */}
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            height="64px"
            paddingLeft="24px"
            paddingRight="12px"
            style={{ flexShrink: 0 }} 
          >
            <Image
              width="130px"
              height="14px"
              src="/assets/images/logo.svg"
              alt="Thirdworks Logo"
              style={{ aspectRatio: "130 / 13.45" }}
            />
            <FlexBox alignItems="center" style={{ gap: "16px" }}>
              <LanguageMenu />
              <Image
                width="24px"
                height="24px"
                src="/assets/icons/closeButton.svg"
                alt="Close"
                style={{ cursor: "pointer" }}
                onClick={toggleDrawer}
              />
            </FlexBox>
          </FlexBox>

          {/* Navigation Links */}
          <FlexBox
  flexDirection="column"
  alignItems="flex-start"
  style={{
    gap: "34px",
    padding: "0px 16px",
    marginTop: "16px",
    width: "100%",
    overflowY: "auto",
    flexGrow: 1,
    alignSelf: "stretch"
  }}
>
  {navItems.map((item) => {
    if (item.isDropdown) {
      return (
        <div key={item.key} style={{ width: "100%" }}>
          <div
            onClick={() => setIsDashboardOpen((prev) => !prev)}
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "140%",
              letterSpacing: "-0.16px",
              color: "#000",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
              cursor: "pointer",
            }}
          >
            {t("dashboard")}
            <img
              src="/assets/icons/caretDown.svg"
              alt="toggle"
              width={16}
              height={16}
              style={{ transform: isDashboardOpen ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </div>
          {isDashboardOpen && <MobileDropdownMenu onClose={toggleDrawer} />}
        </div>
      );
    }

    if (item.labelKey) {
      return (
        <div
          key={item.key}
          onClick={() => {
            handleNavClick(item);
            setIsDashboardOpen(false);
          }}
          style={{
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "140%",
            letterSpacing: "-0.16px",
            color: "#000",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {t(item.labelKey)}
        </div>
      );
    }

    return null;
  })}
          </FlexBox>

          {/* Bottom CTA Buttons */}
          <FlexBox
  flexDirection="column"
  style={{
    marginTop: "auto",
    borderTop: "1px solid #E6E6E6",
    paddingTop: "16px",
    paddingBottom: "32px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "100%",
    gap: "10px",
  }}
>
  {!signedIn && (
    <CTAButton
      style={{ width: "100%", height: "48px" }}
      onClick={() => {
        navigate("/login");
        toggleDrawer();
      }}
    >
      {t("startForFree")}
    </CTAButton>
  )}

  {signedIn ? (
        <>
     <FlexBox
     alignItems="center"
     justifyContent="center" 
     style={{
       width: "100%",
       height: "48px",
       borderRadius: "8px",
       border: "1px solid #E6E6E6",
       backgroundColor: "#fff",
       color: "#333",
       fontSize: "16px",
       fontWeight: 600,
       lineHeight: "140%",
       letterSpacing: "-0.16px",
       cursor: "pointer",
       gap: "8px",
     }}
     onClick={() => {
       navigate("/my?index=0");
       toggleDrawer();
     }}
   >
     <Image
       src="/assets/icons/userCircle.svg"
       alt="user"
       width="24px"
       height="24px"
     />
     <span>
       {LocalStorageManager.getUserName()}
       {i18n.language === "kr" ? "님의 마이페이지" : "'s my page"}
     </span>
   </FlexBox>

   {/* 로그아웃 버튼 */}
   <button
  onClick={handleLoginOrLogout}
  style={{
    width: "100%",
    height: "48px",
    borderRadius: "8px",
    border: "1px solid #E6E6E6",
    backgroundColor: "#fff",
    color: "#333",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  }}
>
<Image
       src="/assets/icons/logout.svg"
       alt="logout"
       width="24px"
       height="24px"
     />
  {t("logout")}
</button>
 </>
  ) : (
    <button
    onClick={handleLoginOrLogout}
    style={{
      width: "100%",
      height: "48px",
      borderRadius: "8px",
      border: "1px solid #E6E6E6",
      backgroundColor: "#fff",
      color: "#333",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: "-0.16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }}
  >
    {t("login")}
  </button>
  )}
</FlexBox>
        </FlexBox>
      </Drawer>
    </>
  );
};

export default MobileAppBar;