import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import { reactiveValueInContent } from "../../utils/StyleUtils";

const BannerH1 = styled(Text)`
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  font-size: ${reactiveValueInContent("48px", "40px")};
  letter-spacing: -0.48px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    color: var(--Black, #000);
    letter-spacing: -0.4px;
  }
`;

export default BannerH1;