import React, { MouseEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../modules";
import ReactGA from "react-ga4";
import { ContentContainer } from "../styledcomponents/Containers";
import FlexBox from "styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import styled from "styled-components";
import CommonComponent from "../styledcomponents/CommonComponent";
import { Colors, Fonts } from "../styledcomponents/Styles";
import { KeyboardUtils } from "../utils/KeyboardUtils";
import { Logger } from "../log/Logger";
import { LocalStorageManager } from "../LocalStorageManager";
import { Users } from "../modules/Users";
import { useTranslation } from "react-i18next";
import { Shadows } from "@mui/material";
import CTAButton from "../styledcomponents/CtaButton";
import { Configurations } from "../modules/Configurations";
import { createTheme } from "@mui/material/styles";
import DropdownMenu from "../components/DropdownMenu"; 
import LanguageMenu from "../components/LanguageMenu";
import UserDropdownMenu from "../components/UserDropdownMenu";

const theme = createTheme({
  shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
  components: {
    MuiPaper: {
      defaultProps: {
        sx: {
          background: Colors.WHITE,
          padding: "4px",
          marginTop: "4px",
          borderRadius: "12px",
          boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
          border: `1px solid ${Colors.GRAY_3}`,
        },
      },
    },
    MuiList: {
      defaultProps: {
        sx: {
          padding: "0px",
        },
      },
    },
  },
  palette: {
    action: {
      selected: Colors.MAIN,
    },
  },
});

interface NavigationItemProps {
  selected: boolean;
}

const NavigationItem = styled(CommonComponent)<NavigationItemProps>`
  font-weight: ${({ selected }) =>
    selected ? Fonts.WEIGHT_700 : Fonts.WEIGHT_500};
  color: #111; // 항상 검정
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05); 
  }
`;


const PcAppBar = () => {
  const { signedIn } = useSelector((state: RootState) => state.usersReducer, shallowEqual);
  const { language } = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const componentName = "PcAppBar";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let pathname = location.pathname;
    if (pathname === "/") setSelectedMenuIndex(0);
    else if (pathname === "/aboutUs") setSelectedMenuIndex(1);
    else if (pathname === "/dashboard") setSelectedMenuIndex(2);
    else if (pathname === "/sourcing") setSelectedMenuIndex(3); 
    else if (pathname === "/sourcing-inquiry") setSelectedMenuIndex(4);
    else setSelectedMenuIndex(999);
  }, [location]);

  function handleLoginOrLogout(event: MouseEvent<any>) {
    if (signedIn) {
      ReactGA.event({ category: "pc_app_bar", action: `logout` });
      Logger.info(componentName, `logout. userId: ${LocalStorageManager.getUserId()}`);
      dispatch(Users.logout());
      navigate("/");
    } else {
      ReactGA.event({ category: "pc_app_bar", action: `login` });
      if (KeyboardUtils.isNewWindowTriggerKey(event)) window.open("/login");
      else navigate("/login");
    }
  }

  return (
    <FlexBox
      width="100%"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(12px)",
        borderBottom: "1px solid #e5e7eb",
      }}
    >
      <FlexBox
        width="100%"
        maxWidth="1920px"
        margin="0 auto"
        paddingLeft="64px"
        paddingRight="64px"
        alignItems="center"
        justifyContent="space-between"
        minHeight="64px"
      >
        {/* 좌측 로고 */}
        <FlexBox style={{ flex: 1 }}>
          <Image
            width="174px"
            height="18px"
            style={{ flexShrink: 0 }}
            src="/assets/images/logo.svg"
            cursor="pointer"
            onClick={() => {
              ReactGA.event({ category: "app_bar", action: `logo_click` });
              navigate("/");
            }}
            alt="logo"
          />
        </FlexBox>

        {/* 가운데 메뉴 */}
        <FlexBox style={{ flex: 2, justifyContent: "center", gap: "40px" }}>
          <NavigationItem selected={selectedMenuIndex === 0} onClick={() => navigate("/")}>
            {t("home")}
          </NavigationItem>
          <NavigationItem selected={selectedMenuIndex === 1} onClick={() => window.location.href = "https://about.thirdworks.co.kr/"}>
    {t("about")}
  </NavigationItem>

  {/* 드롭다운 */}
  <div>
    <DropdownMenu />
  </div>
  <NavigationItem selected={selectedMenuIndex === 3} onClick={() => window.location.href = "https://sourcing.third.works/"}>
    {t("sourcing")}
  </NavigationItem>

  <NavigationItem selected={selectedMenuIndex === 4} onClick={() => navigate("/sourcing-inquiry")}>
    {t("sourcing_inquiry")}
  </NavigationItem>
        </FlexBox>

        {/* 우측: 언어 / 로그인 / CTA 버튼 */}
        <FlexBox style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "24px",
            whiteSpace: "nowrap", 
            }}>
          <LanguageMenu />

          {signedIn ? (
             <UserDropdownMenu />
          ) : (
            <>
              <span
                onClick={handleLoginOrLogout}
                style={{ color: "#111", fontWeight: 600, cursor: "pointer" }}
              >
                {t("login")}
              </span>
              <CTAButton onClick={() => navigate("/login")}>
                {t("startForFree")}
              </CTAButton>
            </>
          )}

        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default PcAppBar;