import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewH5 = styled(Text)`
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-feature-settings: 'clig' off, 'liga';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: #000;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 14px;
  }
`;

export default NewH5;