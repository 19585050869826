import React from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import H2 from "../../styledcomponents/text/H2";
import B2 from "../../styledcomponents/text/B2";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface SolutionFeatureProps {
  title: string;
  description: string;
  images: { kr: string[]; en: string[] };
}

// 🟦 뱃지 스타일
const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.16px;
  white-space: nowrap;
  margin-left: 16px;
`;

// 🟦 제목 + 뱃지를 감싸는 row 박스
const RowBox = styled.div`
  display: flex;
  align-items: center;
`;

const ImageBox = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  width: 100%;
  min-width: 343px;
  min-height: 343px;
  aspect-ratio: 1 / 1;

  @media (min-width: 768px) {
    height: 522px;
    flex: 1 0 0;
  }

  @media (min-width: 1280px) {
    height: 588px;
    flex: 1 0 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  justify-content: center; /* ✅ 기본은 가운데 정렬 */
  margin-top: 60px;

  @media (min-width: 768px) {
    justify-content: flex-start; /* ✅ 태블릿 이상에서 왼쪽 정렬 */
  }
`;


const SolutionFeatureSection = ({ title, description, images }: SolutionFeatureProps) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language as "kr" | "en";
  const isMultiline = typeof description === "string" && description.includes("\n");


  return (
    <FlexBox
    flexDirection="column"
    width="100%"
    alignItems="center"
    background="#FFF"
    padding="144px 20px"
  >
    {/* ✅ 공통 래퍼: 텍스트와 이미지 함께 담기 */}
    <FlexBox
      flexDirection="column"
      width="100%"
      maxWidth="1200px"
      alignItems="flex-start"
      padding="0 24px"
    >
      {/* 텍스트 영역 */}
      <RowBox>
        <H2 reactive>{title}</H2>
        <Badge>{t("dropdown.badge_coming_soon")}</Badge>
      </RowBox>

      <B2
        reactive
        marginTop="40px"
        whiteSpace={isMultiline ? "pre-line" : "normal"}
      >
        {description}
      </B2>

      {/* ✅ 이미지 그룹 (왼쪽 정렬로 변경) */}
      <ImageWrapper style={{ justifyContent: "flex-start" }}>
        {images[lang].map((src, idx) => (
          <ImageBox
            key={idx}
            style={{ backgroundImage: `url(/${src})` }}
          />
        ))}
      </ImageWrapper>
    </FlexBox>
  </FlexBox>
);
};

export default SolutionFeatureSection;