// src/styledcomponents/CTAButton.tsx
import styled from 'styled-components';
import { Colors } from './Styles';

const CTAButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 8px 16px;
  height: 42px;

  width: fit-content;
  max-width: 100%;

  background-color: #101CD7;
  color: white;
  font-size: 16px;
  font-weight: 600;

  border: none;
  border-radius: 8px;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0E1BB8;
  }

  @media (min-width: 1280px) {
    height: 50px;
    padding: 12px 24px;
    gap: 16px;
  }
`;

export default CTAButton;