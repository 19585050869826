// src/components/home/SourcingSection.tsx
import React from "react";
import { featureSections, FeatureSection } from "../home/FeatureSections";
import MainFeatureSection from "./MainFeatureSection";
import { useTranslation } from "react-i18next";

const SourcingSection = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language as "kr" | "en";

  const sourcing = featureSections.find((f: FeatureSection) => f.id === "sourcing");

  if (!sourcing) return null;

  return (
    <MainFeatureSection
      title={sourcing.title[lang]}
      description={sourcing.description[lang]}
      image={sourcing.image?.[lang] ?? ""}
      buttonText={t("quick_access_sourcing") as string}
      onButtonClick={() => {
        window.location.href = "https://sourcing.third.works/";
      }}
    />
  );
};

export default SourcingSection;