import React, { useEffect, useState } from "react";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import { useTranslation } from "react-i18next";
import useMobile from "../uses/UseMobile"; 
import NewH6 from "../styledcomponents/text/NewH6";
import NewH7 from "../styledcomponents/text/NewH7";
interface DropdownItem {
  key: string;
  title: string;
  description: string;
  href: string;
  icon: string;
  badge?: string;
}

interface Props {
  onClose: () => void;
}

const MobileDropdownMenu: React.FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState<DropdownItem[]>([]);
  const isMobile = useMobile();

  useEffect(() => {
    setItems([
      {
        key: "import_trend",
        title: t("dropdown.import_trend.title"),
        description: t("dropdown.import_trend.description"),
        href: "/dashboard?index=0",
        icon: "/assets/icons/importIcon.svg",
      },
      {
        key: "supply_analysis",
        title: t("dropdown.supply_analysis.title"),
        description: t("dropdown.supply_analysis.description"),
        href: "/dashboard?index=1",
        icon: "/assets/icons/supplyIcon.svg",
      },
      {
        key: "market_intelligence",
        title: t("dropdown.market_intelligence.title"),
        description: t("dropdown.market_intelligence.description"),
        href: "#",
        icon: "/assets/icons/marketAIicon.svg",
        badge: t("dropdown.badge_coming_soon") as string
      },
      {
        key: "custom_research",
        title: t("dropdown.custom_research.title"),
        description: t("dropdown.custom_research.description"),
        href: "#",
        icon: "/assets/icons/customizedMarketIcon.svg",
        badge: t("dropdown.badge_coming_soon") as string
      },
    ]);
  }, [t, i18n.language]);

  const handleClick = (item: DropdownItem, e: React.MouseEvent) => {
    if (item.badge) {
      e.preventDefault();
      alert(t("alert.comingSoon"));
    } else {
      window.location.href = item.href;
      onClose();
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      style={{
        gap: i18n.language === "en" ? "10px" : "8px",
        padding: "0", // ✅ 전체 패딩 제거
        width: "100%",
      }}
    >
     {items.map((item) => (
  <FlexBox
    key={item.key}
    onClick={(e) => handleClick(item, e)}
    flexDirection="column"
    alignItems="flex-start"
    style={{
        width: "100%", // ✅ 전체 너비로
        padding: "16px 24px", // ✅ 일관된 양쪽 여백
        borderRadius: "0px", // ✅ 카드 느낌 없이
        backgroundColor: "#FFF",
        gap: "10px",
        cursor: "pointer",
        transition: "background 0.2s",
      }}
    onMouseEnter={(e) => (e.currentTarget.style.background = "#F5F5F5")}
    onMouseLeave={(e) => (e.currentTarget.style.background = "#FFF")}
  >
    {/* 아이콘 + 텍스트 */}
    <FlexBox alignItems="center" style={{ gap: "12px" }}>
      <FlexBox
        width="48px"
        height="48px"
        borderRadius="8px"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "#E6E7FC", flexShrink: 0 }}
      >
        <Image src={item.icon} width="40px" height="40px" alt="icon" />
      </FlexBox>

      <FlexBox flexDirection="column" style={{ flex: 1, gap: "6px" }}>
         <FlexBox alignItems="center" style={{ gap: "8px" }}>
          <NewH6>{item.title}</NewH6>
          {item.badge && typeof item.badge === "string" && (
             <span
             style={{
              display: "flex",
              height: "22px",
              padding: "6px 8px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              borderRadius: "4px",
              background: "#E6E6E6", // var(--Gray-90)
              color: "#666",        // var(--Gray-40)
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "140%",
              letterSpacing: "-0.1px",
              whiteSpace: "nowrap",
             }}
           >
             {item.badge}
           </span>
          )}
        </FlexBox>
        <NewH7>
          {item.description}
        </NewH7>
      </FlexBox>
    </FlexBox>
  </FlexBox>
))}
    </FlexBox>
  );
};

export default MobileDropdownMenu;
