import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import { reactiveValueInContent } from "../../utils/StyleUtils";

const H2 = styled(Text)`
  color: var(--Gray-20, #333);
  font-size: ${({ reactive }) => reactive ? reactiveValueInContent("24px", "20px") : "24px"};
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 33.6px at 24px */
  letter-spacing: -0.24px;

    @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    letter-spacing: -0.2px;
  }
`;

export default H2;