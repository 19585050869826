import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";


const NewH2 = styled(Text)`
  color: #000;
  font-family: Pretendard;
  font-size: var(--Font-Size-Head-5, 28px);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  letter-spacing: -0.48px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 24px;
  }
`;



export default NewH2;