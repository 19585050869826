import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import { reactiveValueInContent } from "../../utils/StyleUtils";

const B2 = styled(Text)`
  color: var(--Black, #000);
  font-family: Pretendard;
  font-size: ${({ reactive }) => reactive ? reactiveValueInContent("36px", "24px") : "36px"};
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 57.6px at 36px */
  letter-spacing: -0.36px;

     @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    letter-spacing: -0.24px;
  }
`;

export default B2;