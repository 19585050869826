import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import styles from "./PartnerLogosCarousel.module.css";
import { useTranslation } from "react-i18next";
import FlexBox from "../../styledcomponents/FlexBox";
import useMobile from "uses/UseMobile";

const partners = [
  { id: 1, name: "DALEUM", logo: "assets/images/daleumLogo.png" },
  { id: 2, name: "OPUS", logo: "assets/images/opusLogo.png" },
  { id: 3, name: "서울동북권기업발전협회", logo: "assets/images/seoulbizLogo.png" },
];

const duplicatedPartners = [...partners, ...partners, ...partners];

const PartnerLogosCarousel = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const h2Style: React.CSSProperties = {
    color: "#000",
    textAlign: "center", 
    fontSize: isMobile ? "36px" : "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%",
    letterSpacing: isMobile ? "-0.36px" : "-0.4px",
    marginBottom: "32px",
  };


  return (
    <FlexBox flexDirection="column" width="100%" alignItems="center">
        <h2 style={h2Style}>{t("partners.title")}</h2>


      <div className={styles.carouselWrapper}>
        <div className={styles["gradient-left"]}></div>

        <Swiper
          modules={[Autoplay, Navigation]}
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
          style={{ width: "100%" }}
        >
          {duplicatedPartners.map((partner, index) => (
            <SwiperSlide
              key={`${partner.id}-${index}`}
              style={{ display: "flex", justifyContent: "center", alignItems: "center",  height: "120px",  }}
            >
              <div style={{ height: "96px", display: "flex", alignItems: "center", justifyContent: "center", padding: "16px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/" + partner.logo}
                  alt={partner.name}
                  className={styles.partnerLogo}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={styles["gradient-right"]}></div>
      </div>
    </FlexBox>
  );
};

export default PartnerLogosCarousel;