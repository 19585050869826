// src/components/home/FeatureSections.ts

export interface FeatureSection {
    id: string;
    title: { kr: string; en: string };
    description: { kr: string; en: string };
    image?: { kr: string; en: string };
    images?: { kr: string[]; en: string[] };
  }
  
  export const featureSections: FeatureSection[] = [
    {
      id: "dashboard",
      title: {
        kr: "대시보드",
        en: "Dashboard"
      },
      description: {
        kr: "기업이 원하는 공급망 파트너를\n쉽고 빠르게 찾아보세요.",
        en: "Quickly and easily find the supply chain partners\nyour business needs."
      },
      image: {
        kr: "assets/images/features/dashboard-img-kr.png",
        en: "assets/images/features/dashboard-img-en.png"
      }
    },
    {
      id: "sourcing",
      title: {
        kr: "소싱",
        en: "Sourcing"
      },
      description: {
        kr: "신규 상품 소싱부터 기존 상품의\n품질까지 꼼꼼히 비교해보세요.",
        en: "Compare new product sourcing\nand existing product quality in detail. "
      },
      image: {
        kr: "assets/images/features/sourcing-img-kr.png",
        en: "assets/images/features/sourcing-img-en.png"
      }
    },
    {
      id: "solution",
      title: {
        kr: "솔루션",
        en: "Solution"
      },
      description: {
        kr: "복잡한 무역 계약서와 통관서류를\n편하게 작성하고 관리해보세요.",
        en: "Easily draft and manage\ncomplex trade contracts and customs documents."
      },
      images: {
        kr: [
          "assets/images/features/solution1-img-kr.png",
          "assets/images/features/solution2-img-kr.png"
        ],
        en: [
          "assets/images/features/solution1-img-en.png",
          "assets/images/features/solution2-img-en.png"
        ]
      }
    }
  ];