import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import Text from "../styledcomponents/Text";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import {NewDashboardApi} from "../network/NewDashboardApi";
import {CompanyModel} from "../model/CompanyModel";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import H5 from "../styledcomponents/text/H5";
import S1 from "../styledcomponents/text/S1";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import {MarketShareChart, MarketShareChartModel} from "../components/chart/MarketShareChart";
import {RenderingByState} from "../components/RenderingByState";
import {IncomePatterChartModel, IncomePatternChart} from "../components/chart/IncomePatternChart";
import {ChartXYData} from "../model/ChartXYData";
import Button from "../styledcomponents/Button";
import {Fade} from "react-awesome-reveal";
import {VolumePieChart, VolumePieChartModel} from "../components/chart/VolumePieChart";
import {SupplyAnalysisGraph, SupplyAnalysisGraphModel} from "../components/chart/SupplyAnalysisGraph";
import "./CompanyAnalysisFragment.css"
import {useReactToPrint} from "react-to-print";
import Flag from "react-flagkit";
import {useTranslation} from "react-i18next";
import {AppConfig} from "../AppConfig";
import Image from "../styledcomponents/Image";
import {LocalStorageManager} from "../LocalStorageManager";
import SampleRequestDialog from "../dialog/SampleRequestDialog";
import {TimeUtils} from "../utils/TimeUtils";
import useTablet from "../uses/UseTablet";


type SupplyColorSet = {
    color: string,
    backgroundColor: string
}

const supplyColorSet: Array<SupplyColorSet> = [
    {
        color: Colors.PREMIUM,
        backgroundColor: Colors.BG1_P
    },
    {
        color: Colors.KEY,
        backgroundColor: Colors.BG1_B
    },
    {
        color: Colors.STANDARD,
        backgroundColor: Colors.BG1_G
    },
    {
        color: Colors.EXTRA1,
        backgroundColor: Colors.BG_EXTRA1
    },
    {
        color: Colors.EXTRA2,
        backgroundColor: Colors.BG_EXTRA2
    },
]
//TODO 권한 체크
const CompanyAnalysisFragment: React.FC = () => {

    const {accessToken} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const navigate = useNavigate()
    const {companyId} = useParams();
    const [tradeType, setTradeType] = useState<string | null>(null)
    const [companyModel, setCompanyModel] = useState<CompanyModel | null>(null)
    const [marketShareModels, setMarketShareModels] = useState<Array<MarketShareChartModel> | null>(null)
    const [volumePieChartModels, setVolumePieChartModels] = useState<Array<VolumePieChartModel> | null>(null)
    const [incomePatternChartModels, setIncomePatternChartModels] = useState<Array<IncomePatterChartModel> | null>(null)
    const [supplyAnalysisGraphModels, setSupplyAnalysisGraphModels] = useState<Array<Array<Array<SupplyAnalysisGraphModel>>> | null>(null)
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isShowHsCodeUnitInfo, setIsShowHsCodeUnitInfo] = useState<boolean>(() => {
        let lastExposedTimestamp = Number(LocalStorageManager.getExposedHsCodeUnitInfo())
        let currentTimestamp = Date.now()
        let gap = Math.abs(lastExposedTimestamp - currentTimestamp)
        return gap >= TimeUtils.ONE_DAY
    })
    const [isShowInquiryDialog, setIsShowInquiryDialog] = useState(false)

    const hsCode = searchParams.get('hsCode');

    const contentRef = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });

    const isTablet = useTablet()

    function isInvalidState(): boolean {
        return !accessToken && hsCode !== AppConfig.freeHsCode
    }

    useEffect(() => {
        if (!accessToken && hsCode !== AppConfig.freeHsCode) {
            alert(t('alert_login_is_required'))
            navigate("/login")
        }
    }, [accessToken])

    useEffect(() => {
        if (!companyId || isInvalidState()) {
            return;
        }
        NewDashboardApi.getCompany(companyId, hsCode, language)
            .then((response) => {
                const companyModel = response.data.data
                if (companyModel) {
                    setTradeType(companyModel.companyType)
                    setCompanyModel(companyModel)
                } else {
                    setCompanyModel({
                        companyId: companyId,
                        companyNm: "Unknown-Company",
                        countryNm: "Unknown-Country",
                        companyType: "",
                        countryCode: ""
                    })
                }
            });
    }, [companyId, accessToken, language]);

    useEffect(() => {
        if (!companyId || isInvalidState() || !tradeType) {
            return;
        }
        NewDashboardApi.getCompanyMarket(companyId, tradeType, hsCode, language)
            .then((response) => {
                let result = response.data.data;
                if (!result || result.length === 0) {
                    setMarketShareModels([])
                    setVolumePieChartModels([])
                    return;
                }

                let marketShareItems = result.map((item) => {
                    let model: MarketShareChartModel = {
                        itemNm: item.itemNm
                    };
                    model[t('company_count')] = parseInt(item.companyCnt);
                    model[t('total_company_count')] = parseInt(item.totalCnt);
                    return model;
                });
                setMarketShareModels(marketShareItems)

                let volumePieItems = result.map((item) => {
                    return {
                        id: item.itemNm,
                        label: item.itemNm,
                        value: Number(item.itemWeight)
                    } as VolumePieChartModel
                })
                setVolumePieChartModels(volumePieItems)
            })
            .catch((reason) => {
                setMarketShareModels([])
                setVolumePieChartModels([])
            })
    }, [companyId, accessToken, tradeType, language])

    useEffect(() => {
        if (!companyId || isInvalidState() || !tradeType) {
            return
        }
        NewDashboardApi.getCompanyPattern(companyId, tradeType, hsCode, language)
            .then((response) => {
                const result = response.data.data
                if (!result || result.length === 0) {
                    setIncomePatternChartModels([])
                    return;
                }
                const incomePatternItems: Array<IncomePatterChartModel> = [];
                result.forEach((yearData) => {
                    let chartData = yearData.map((incomePatternModel) => {
                        return {
                            x: `${incomePatternModel.year}.${incomePatternModel.month}`,
                            y: Number(incomePatternModel.cnt)
                        } as ChartXYData
                    })
                    incomePatternItems.push({
                        id: yearData[0].itemNm,
                        data: chartData
                    } as IncomePatterChartModel)
                })
                setIncomePatternChartModels(incomePatternItems)
            })
            .catch((reason) => {
                setIncomePatternChartModels([])
            })

    }, [companyId, accessToken, tradeType, language])

    useEffect(() => {
        if (!companyId || isInvalidState() || !tradeType) {
            return
        }
        NewDashboardApi.getCompanySupply(companyId, tradeType, hsCode, language)
            .then((response) => {
                const result = response.data.data
                if (!result || result.length === 0) {
                    setSupplyAnalysisGraphModels([])
                    return;
                }
                const itemNameMap = new Map<string, Array<SupplyAnalysisGraphModel>>()
                result.forEach((item) => {
                    const buildArray = itemNameMap.get(item.itemNm) || new Array<SupplyAnalysisGraphModel>()
                    buildArray.push({
                        companyNm: item.companyNm,
                        importerNm: item.importerNm,
                        itemNm: item.itemNm,
                        productNm: item.productNm,
                        avgCnt: item.avgCnt,
                        processingDt: item.processingDt,
                        textColor: "",
                        backgroundColor: ""
                    } as SupplyAnalysisGraphModel)
                    itemNameMap.set(item.itemNm, buildArray)
                })
                const itemGroupList: Array<Array<Array<SupplyAnalysisGraphModel>>> = []
                let itemIndex = -1
                itemNameMap.forEach((groupByItemName) => {
                    itemIndex++
                    groupByItemName.sort((a, b) => {
                        if (a.itemNm === b.itemNm) {
                            return a.importerNm.localeCompare(b.importerNm);
                        }
                        return a.itemNm.localeCompare(b.itemNm);
                    });
                    const circularIndex = itemIndex % supplyColorSet.length
                    const textColor = supplyColorSet[circularIndex].color
                    const backgroundColor = supplyColorSet[circularIndex].backgroundColor

                    const importerNameMap = new Map<string, Array<SupplyAnalysisGraphModel>>()
                    groupByItemName.forEach(item => {
                        const buildArray = importerNameMap.get(item.importerNm) || new Array<SupplyAnalysisGraphModel>()
                        buildArray.push({
                            companyNm: item.companyNm,
                            importerNm: item.importerNm,
                            itemNm: item.itemNm,
                            productNm: item.productNm,
                            avgCnt: item.avgCnt,
                            processingDt: item.processingDt,
                            textColor: "",
                            backgroundColor: ""
                        } as SupplyAnalysisGraphModel)
                        importerNameMap.set(item.importerNm, buildArray)
                    })
                    const importerGroupList: Array<Array<SupplyAnalysisGraphModel>> = []
                    importerNameMap.forEach((groupByImporterName) => {
                        groupByImporterName.sort((a, b) => {
                            return a.importerNm.localeCompare(b.importerNm);
                        });
                        importerGroupList.push(groupByImporterName.map((item) => {
                            return {
                                companyNm: item.companyNm,
                                importerNm: item.importerNm,
                                itemNm: item.itemNm,
                                productNm: item.productNm,
                                avgCnt: item.avgCnt,
                                processingDt: item.processingDt,
                                textColor: textColor,
                                backgroundColor: backgroundColor
                            } as SupplyAnalysisGraphModel
                        }))
                    })
                    itemGroupList.push(importerGroupList)
                })

                setSupplyAnalysisGraphModels(itemGroupList)

            })
            .catch((r) => {
                setSupplyAnalysisGraphModels([[[]]])
            })

    }, [companyId, accessToken, tradeType, language])

    function closeInquiryMessage() {
        LocalStorageManager.setExposedHsCodeUnitInfo(Date.now().toString())
        setIsShowHsCodeUnitInfo(false)
    }

    return (
        <FlexBox
            width={"100%"}
            minWidth={isTablet ? undefined : "1200px"}
            height={"100%"}
            overflow={"auto"}
            flexDirection={"column"}>
            {isShowHsCodeUnitInfo && <FlexBox
    top={isTablet ? "0px" : "64px"} // Add 64px to account for the app bar height
    justifyContent={isTablet ? "space-between" : "center"}
    position={isTablet ? "relative" : "absolute"}
    width={"100%"}
    height={isTablet ? "fit-content" : "60px"}
    padding={"24px"}
    minWidth={isTablet ? "100%" : "1340px"}
    zIndex={1} // Make sure this is lower than the app bar's z-index (1000)
    alignItems={"center"}
    backgroundColor={Colors.GREY_30}>
                <Text
                    width={isTablet ? "300px" : undefined}
                    fontSize={"16px"}>
                    {t('company_analysis_hs_code_unit_info')}
                </Text>
                <Button
                    marginRight={"60px"}
                    height={"40px"}
                    width={"100px"}
                    fontSize={"16px"}
                    alignSelf={isTablet ? "flex-start" : undefined}
                    marginLeft={isTablet ? "20px" : "200px"}
                    backgroundColor={Colors.GREY_70}
                    onClick={() => {
                        setIsShowInquiryDialog(true)
                    }}>
                    {t('contact_by_inquiry')}
                </Button>
                <Image
                    id={"search-close"}
                    cursor={"pointer"}
                    visible={true}
                    position={"absolute"}
                    top={isTablet ? "24px" : undefined}
                    right={isTablet ? "20px" : "40px"}
                    alt={"hs-code-unit-info-close"}
                    src={require("assets/images/search-close.svg").default}
                    onClick={(e) => {
                        closeInquiryMessage()
                    }}
                    width={"18px"}/>
            </FlexBox>}
            <FlexBox
    width={"100%"}
    height={"100%"}
    minWidth={isTablet ? "100%" : "1340px"}
    minHeight={isTablet ? "1800px" : "2260px"}
    paddingTop={isTablet ? "124px" : "184px"} // Increase padding to account for app bar
    paddingBottom={isTablet ? "0px" : "100px"}
    backgroundColor={Colors.BG_PAGE}
    paddingLeft={isTablet ? "20px" : "0px"}
    paddingRight={isTablet ? "20px" : "0px"}
    alignItems={"center"}
    flexDirection={"column"}>

                <FlexBox
                    id={"content-container"}
                    ref={contentRef}
                    margin={"0 auto"}
                    width={isTablet ? "100%" : "1300px"}
                    minHeight={"2048px"}
                    flexDirection={"column"}
                    alignItems={"center"}>
                    {/*--------------------------- 타이틀 영역 ---------------------------*/}
                    <FlexBox
                        id={"company-title-layout"}
                        height={"fit-content"}
                        alignSelf={"flex-start"}
                        alignItems={isTablet ? "flex-start" : "center"}
                        flexDirection={isTablet ? "column" : "row"}>
                        <FlexBox
                            alignItems={"center"}>
                            {companyModel?.countryCode &&
                                <Flag
                                    style={{
                                        borderRadius: isTablet ? "10px" : "20px",
                                        clipPath: "border-box"
                                    }}
                                    country={companyModel.countryCode}
                                    size={isTablet ? 48 : 96}/>}
                            <Text
                                fontWeight={700}
                                marginLeft={companyModel?.countryCode ? (isTablet ? "20px" : "40px") : "0px"}
                                fontSize={isTablet ? "24px" : "36px"}>{companyModel?.companyNm}
                            </Text>
                        </FlexBox>
                        <FlexBox alignItems={"center"}
                                 marginTop={isTablet ? "6px" : "0px"}>
                            <Text
                                fontWeight={500}
                                fontSize={isTablet ? "20px" : "28px"}
                                lineHeight={"28px"}
                                marginLeft={isTablet ? "0px" : "30px"}
                                color={Colors.KEY}>{companyModel?.countryNm}
                            </Text>
                            <VerticalDivider
                                height={"24px"}
                                marginLeft={"15px"}
                                backgroundColor={Colors.SUB_TXT}
                            />
                            <Text
                                fontWeight={500}
                                fontSize={isTablet ? "20px" : "28px"}
                                lineHeight={"28px"}
                                marginLeft={"15px"}
                                color={Colors.SUB_TXT}>{companyModel?.companyType ?
                                companyModel.companyType === "exp" ? t('company_' + companyModel.companyType) : t('company_' + companyModel.companyType) : ""}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    {/*--------------------------- 시장점유율 차트 ---------------------------*/}
                    <FlexBox
                        width={"100%"}
                        marginTop={"50px"}
                        flexDirection={isTablet ? "column" : "row"}>
                        <FlexBox
                            width={"100%"}
                            height={"390px"}
                            border={`1px solid ${Colors.GREY_BORDER}`}
                            backgroundColor={Colors.WHITE}
                            borderRadius={"10px"}
                            flexDirection={"column"}
                            paddingLeft={isTablet ? "20px" : "30px"}
                            paddingRight={isTablet ? "20px" : "30px"}
                            paddingTop={"25px"}
                            paddingBottom={"25px"}>
                            <FlexBox
                                alignItems={isTablet ? "flex-start" : "flex-end"}
                                justifyContent={"space-between"}
                                flexDirection={isTablet ? "column" : "row"}>
                                <Text
                                    fontSize={isTablet ? "18px" : "20px"}
                                    fontWeight={700}
                                    lineHeight={"20px"}>
                                    {t('market_share')}
                                </Text>
                                <S1
                                    marginTop={isTablet ? "10px" : "0px"}
                                    lineHeight={"14px"}
                                    color={Colors.SUB_TXT}>
                                    {`${t('records_unit')}, ${t('last_12_months')}`}
                                </S1>
                            </FlexBox>
                            <HorizontalDivider
                                marginTop={"20px"}
                                backgroundColor={Colors.KEY}
                                paddingBottom={"1px"}/>
                            <FlexBox
                                minWidth={isTablet ? "100%" : "600px"}
                                maxWidth={"600px"}
                                height={"100%"}
                                overflow={"auto"}
                                paddingBottom={"10px"}>
                                <FlexBox
                                    width={"100%"}
                                    overflow={"hidden"}
                                    minWidth={marketShareModels?.length ? (280 + marketShareModels?.length * 80) + "px" : "0px"}>
                                    <RenderingByState items={marketShareModels}>
                                        {marketShareModels &&
                                            <MarketShareChart chartModels={marketShareModels}
                                                              key1={t('company_count')}
                                                              key2={t('total_company_count')}
                                                              groupByKey={"itemNm"}/>}
                                    </RenderingByState>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                        {/*--------------------------- 거래비중 차트 ---------------------------*/}
                        <FlexBox
                            width={"100%"}
                            maxWidth={isTablet ? "100%" : "600px"}
                            height={isTablet ? "480px" : "390px"}
                            border={`1px solid ${Colors.GREY_BORDER}`}
                            backgroundColor={Colors.WHITE}
                            borderRadius={"10px"}
                            flexDirection={"column"}
                            paddingLeft={isTablet ? "20px" : "30px"}
                            paddingRight={isTablet ? "20px" : "30px"}
                            paddingTop={"25px"}
                            marginLeft={isTablet ? "0px" : "20px"}
                            marginTop={isTablet ? "20px" : "0px"}>
                            <FlexBox
                                alignItems={isTablet ? "flex-start" : "flex-end"}
                                justifyContent={"space-between"}
                                flexDirection={isTablet ? "column" : "row"}>
                                <FlexBox alignItems={"flex-end"}>
                                    <Text
                                        fontSize={isTablet ? "18px" : "20px"}
                                        fontWeight={700}
                                        lineHeight={"20px"}>
                                        {t('transaction_ratio')}
                                    </Text>
                                    <S1
                                        lineHeight={"14px"}
                                        marginLeft={"10px"}
                                        marginBottom={"2px"}
                                        color={Colors.SUB_TXT}>
                                        ( % )
                                    </S1>
                                </FlexBox>
                                <S1
                                    marginTop={isTablet ? "10px" : "0px"}
                                    lineHeight={"14px"}
                                    color={Colors.SUB_TXT}>
                                    {`${t('records_unit')}, ${t('last_12_months')}`}
                                </S1>
                            </FlexBox>
                            <HorizontalDivider
                                marginTop={"20px"}
                                backgroundColor={Colors.KEY}
                                paddingBottom={"1px"}/>
                            <RenderingByState items={volumePieChartModels}>
                                {volumePieChartModels &&
                                    <Fade duration={300}
                                          triggerOnce={true}
                                          style={{
                                              width: "100%",
                                              height: "100%"
                                          }}>
                                        <VolumePieChart items={volumePieChartModels}/>
                                    </Fade>}
                            </RenderingByState>

                        </FlexBox>
                    </FlexBox>

                    {/*--------------------------- 수입 패턴 차트 ---------------------------*/}
                    <FlexBox
                        width={isTablet ? "100%" : "1300px"}
                        height={"472px"}
                        minHeight={"472px"}
                        border={`1px solid ${Colors.GREY_BORDER}`}
                        backgroundColor={Colors.WHITE}
                        borderRadius={"10px"}
                        marginTop={"30px"}
                        paddingLeft={isTablet ? "20px" : "50px"}
                        paddingRight={isTablet ? "20px" : "50px"}
                        paddingTop={isTablet ? "30px" : "40px"}
                        paddingBottom={isTablet ? "30px" : "40px"}
                        flexDirection={"column"}>
                        <FlexBox
                            alignItems={isTablet ? "flex-start" : "flex-end"}
                            justifyContent={"space-between"}
                            flexDirection={isTablet ? "column" : "row"}>
                            <H5 lineHeight={"20px"}>
                                {t('import_pattern')}
                            </H5>
                            <S1
                                marginTop={isTablet ? "10px" : "0px"}
                                lineHeight={"14px"}
                                color={Colors.SUB_TXT}>
                                {`${t('records_unit')}, ${t('last_12_months')}`}
                            </S1>
                        </FlexBox>
                        <HorizontalDivider
                            marginTop={"20px"}
                            backgroundColor={Colors.KEY}/>
                        <FlexBox
                            width={"100%"}
                            height={"100%"}
                            overflowX={isTablet ? "scroll" : "hidden"}
                            paddingBottom={"10px"}>
                            <RenderingByState items={incomePatternChartModels}>
                                {incomePatternChartModels &&
                                    <Fade duration={300}
                                          triggerOnce={true}
                                          style={{
                                              width: "100%",
                                              height: "100%",
                                              minWidth: isTablet ? "1200px" : undefined
                                          }}>
                                        <IncomePatternChart
                                            chartModels={incomePatternChartModels}
                                            colors={supplyColorSet.map((item) => item.color)}/>
                                    </Fade>}
                            </RenderingByState>
                        </FlexBox>
                    </FlexBox>

                    {/*--------------------------- 공급망 분석 차트 ---------------------------*/}
                    <FlexBox
                        id={"supply-analysis-graph-chart"}
                        width={isTablet ? "100%" : "1300px"}
                        height={isTablet ? "fit-content" : "804px"}
                        border={`1px solid ${Colors.GREY_BORDER}`}
                        backgroundColor={Colors.WHITE}
                        borderRadius={"10px"}
                        marginTop={"30px"}
                        paddingTop={isTablet ? "30px" : "40px"}
                        paddingLeft={isTablet ? "20px" : "40px"}
                        paddingRight={isTablet ? "20px" : "40px"}
                        flexDirection={"column"}>
                        <FlexBox
                            alignItems={isTablet ? "flex-start" : "flex-end"}
                            justifyContent={"space-between"}
                            flexDirection={isTablet ? "column" : "row"}>
                            <H5 lineHeight={"20px"}>
                                {t('supply_chain_analysis')}
                            </H5>
                            <S1
                                marginTop={isTablet ? "10px" : "0px"}
                                lineHeight={"14px"}
                                color={Colors.SUB_TXT}>
                                {t('last_12_months')}
                            </S1>
                        </FlexBox>
                        <FlexBox
                            marginTop={"20px"}
                            paddingTop={"1px"}>
                            <HorizontalDivider
                                backgroundColor={Colors.KEY}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            height={"100%"}
                            overflow={"auto"}>
                            <RenderingByState items={supplyAnalysisGraphModels}>
                                {supplyAnalysisGraphModels &&
                                    <Fade duration={300}
                                          triggerOnce={true}
                                          style={{
                                              width: "100%",
                                              height: "100%",
                                              minWidth: isTablet ? "1200px" : undefined
                                          }}>
                                        <SupplyAnalysisGraph
                                            items={supplyAnalysisGraphModels}/>
                                    </Fade>}
                            </RenderingByState>
                        </FlexBox>
                    </FlexBox>
                    {!isTablet && <Button
                        id={"report-print-button"}
                        width={"360px"}
                        minHeight={"72px"}
                        marginTop={"60px"}
                        fontSize={"24px"}
                        color={Colors.WHITE}
                        onClick={() => {
                            handlePrint()
                        }}>
                        {t('print_report')}
                    </Button>}
                </FlexBox>
            </FlexBox>
            {isShowInquiryDialog
                && <SampleRequestDialog isOpen={isShowInquiryDialog}
                                        onClosed={() => {
                                            setIsShowInquiryDialog(false)
                                        }}/>}
        </FlexBox>
    )
}
export default CompanyAnalysisFragment;