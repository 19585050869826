import React, {useState, useEffect} from "react";
import BannerSection from "../components/home/BannerSection";
import CTABanner from "components/home/CTABanner";
import DashboardSection from "../components/home/DashboardSection";
import SourcingSection from "../components/home/SourcingSection";
import SolutionSection from "../components/home/SolutionSection";
import Partners from "components/home/Partners";
import FAQ from "components/home/FAQ";
import Contact from "components/home/Contact";
import CTABannerButton from "components/home/CtaBannerButton";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import {Colors, Fonts, Sizes} from "../styledcomponents/Styles";
import H1 from "../styledcomponents/text/H1";
import B1 from "../styledcomponents/text/B1";
import useSmall from "../uses/UseSmall";
import {Carousel} from "react-bootstrap";
import Button from "../styledcomponents/Button";
import {reactiveValueInContent, reactiveValueInFHD} from "../utils/StyleUtils";
import "./HomeFragment.css";
import H2 from "../styledcomponents/text/H2";
import B2 from "../styledcomponents/text/B2";
import ReactPlayer from "react-player";
import Text from "../styledcomponents/Text";
import H3 from "../styledcomponents/text/H3";
import B3 from "../styledcomponents/text/B3";
import useMobile from "../uses/UseMobile";
import GradientText from "../styledcomponents/GradientText";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import {useNavigate} from "react-router-dom";
import {SimpleFade} from "../components/SimpleFade";
import {useInView} from "react-intersection-observer";
import useMaxWidth from "../uses/UseMaxWidth";
import {useTranslation} from "react-i18next";
import SampleRequestDialog from "../dialog/SampleRequestDialog";
import AnugaPromotionRequestDialog from "../dialog/AnugaPromotionRequestDialog";
import AnugaPromotionDialog from "../dialog/AnugaPromotionDialog";
import useTablet from "../uses/UseTablet";
import { motion, AnimatePresence } from "framer-motion";

const HomeFragment = () => {

    const isSmall = useSmall()
    const isMobile = useMobile()
    const isTablet = useTablet()
    const isContent = useMaxWidth("1920px")
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [isOpenRequestSampleDialog, setIsOpenRequestSampleDialog] = useState(false)
    const navigate = useNavigate()
    const animationTranslationY = isMobile ? "40px" : "60px"
    const animationDuration = isMobile ? 1000 : 1200
    const [isAnugaPromotionDialog, setIsAnugaPromotionDialog] = useState(false)
    const [isAnugaPromotionRequestDialog, setIsAnugaPromotionRequestDialog] = useState(false)
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
    const [direction, setDirection] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false); 

/// 배너 자동 변경
useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 8000);
    return () => clearInterval(interval);
  }, []);


  const handlePrev = () => {
    setCurrentBannerIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % 3);
  };

    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });

    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const {t} = useTranslation()
    const brTagAboveMobile = isMobile ? " " : "\n"
    const brTagAboveSmallMobile = isSmall ? " " : "\n"

    function handleStartSolution() {
        window.open("https://solution.thirdworks.co.kr/")
    }

    return (

        <FlexBox flexDirection={"column"}
                 width={"100%"}
                 minWidth={isTablet ? undefined : "1200px"}
                 alignItems={"center"}
                 overflow={"hidden"}>
       <BannerSection />
       <CTABanner />
       <DashboardSection />
       <SourcingSection />    
       <SolutionSection />  
       <Partners />  
       <FAQ /> 
       <Contact />
       <CTABannerButton />
         

                    </FlexBox>
        

       

    )
}
export default HomeFragment;