import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import type { Swiper as SwiperClass } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import FlexBox from "../../styledcomponents/FlexBox";
import Image from "../../styledcomponents/Image";
import H1 from "../../styledcomponents/text/H1";
import B1 from "../../styledcomponents/text/B1";
import CTAButton from "../../styledcomponents/CtaButton";
import { Colors } from "../../styledcomponents/Styles";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { SlideWrapper, TextBox, AnimationBox } from "../../styledcomponents/StyledSlideLayout";
import useSmall from "../../uses/UseSmall";
import useTablet from "../../uses/UseTablet";
import useMobile from "../../uses/UseMobile";

const slides = [
  {
    titleKey: "home_main_banner_title",
    descKey: "home_main_banner_subtitle",
    lottie: "/assets/1.lottie",
  },
  {
    titleKey: "home_main_banner_2_title",
    descKey: "home_main_banner_2_subtitle",
    lottie: "/assets/2.lottie",
  },
  {
    titleKey: "home_main_banner_3_title",
    descKey: "home_main_banner_3_subtitle",
    lottie: "/assets/3.lottie",
  },
];

const BannerSection = () => {
  const { t } = useTranslation();
  const isSmall = useSmall();
  const isMobile = useMobile();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <FlexBox
      width="100%"
      height="auto"
      position="relative"
      flexDirection="column"
      alignItems="center"
      overflow="hidden"
    >
      {/* 배경 이미지 */}
      <Image
        src="/assets/images/BG_pc2.png"
        alt="bg"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 0,
        }}
      />

      {/* 콘텐츠 */}
      <FlexBox
        width="100%"
        style={{
          zIndex: 2,
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
        flexDirection="column"
        alignItems="center"
      >
        <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 8000 }}
        loop
        pagination={{ clickable: true }}
        slidesPerView={1}
        onSlideChange={(swiper: SwiperClass) => setActiveIndex(swiper.realIndex)}
        style={{ width: "100%" }}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
             <SlideWrapper>
  {isMobile && (
    <AnimationBox>
     <DotLottiePlayer
  src={slide.lottie}
  autoplay={activeIndex === i}
  loop
  style={{ width: "100%", height: "100%" }}
/>
    </AnimationBox>
  )}

  <TextBox>
    <H1 reactive color={Colors.BLACK}>
      {t(slide.titleKey)}
    </H1>
    <B1 reactive marginTop="20px" color={Colors.BODY_TXT}>
      {t(slide.descKey, { space: isSmall ? " " : "\n" })}
    </B1>

    <CTAButton
      style={{
        marginTop: "40px",
        alignSelf: isMobile ? "center" : "flex-start",
      }}
      onClick={() =>
        window.open("https://solution.thirdworks.co.kr/")
      }
    >
      {t("start_solution")}
    </CTAButton>
  </TextBox>

  {!isMobile && (
    <AnimationBox>
      <DotLottiePlayer
        src={slide.lottie}
        autoplay
        loop
        style={{ width: "100%", height: "100%" }}
      />
    </AnimationBox>
  )}
</SlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </FlexBox>
    </FlexBox>
  );
};

export default BannerSection;