import React from "react";
import { useTranslation } from "react-i18next";
import FlexBox from "../../styledcomponents/FlexBox";
import BannerH1 from "../../styledcomponents/text/BannerH1";
import useMobile from "../../uses/UseMobile";

const CTABanner = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMobile();

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minWidth="375px"
      padding="56px 16px"
      background="#EEEFFE"
      style={{
        textAlign: "center",
        gap: "8px",
      }}
    >
      {/* 설명 문구 */}
      <BannerH1 style={{ whiteSpace: isMobile ? "pre-line" : "normal" }}>
        {t("banner_cta.description")}
      </BannerH1>

      {/* 강조 문구 (언어에 따라 순서 변경) */}
      <BannerH1 style={{ whiteSpace: isMobile ? "pre-line" : "normal" }}>
  {i18n.language === "kr" ? (
    <>
      {t("banner_cta.title")}{" "}
      <span style={{ color: "#7373FE" }}>{t("banner_cta.highlight")}</span>
    </>
  ) : (
    <>
      <span style={{ color: "#7373FE" }}>{t("banner_cta.highlight")}{" "}</span>{" "}
      {t("banner_cta.title")}
    </>
  )}
</BannerH1>
    </FlexBox>
  );
};

export default CTABanner;