import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {Logger} from "./log/Logger";
import {en, kr} from "./resources/locales";
import {LocalStorageManager} from "./LocalStorageManager";

i18n.use(initReactI18next)
    .init({
        resources: {
            en: en,
            kr: kr
        },
        lng: "kr", // 초기 설정 언어
        // LocalStorageManager.getLanguage(), 
        fallbackLng: "en",
        ns: ["translation"],
        defaultNS: "translation", 
        debug: true,
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    }, (error, t) => {
        Logger.error("i18n", error)
    });

export default i18n;