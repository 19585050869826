import React from "react";
import { featureSections, FeatureSection } from "../home/FeatureSections";
import MainFeatureSection from "./MainFeatureSection";
import { useTranslation } from "react-i18next";

const DashboardSection = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language as "kr" | "en";

const dashboard = featureSections.find((f: any) => f.id === "dashboard");

  if (!dashboard) return null;

  return (
    <MainFeatureSection
      title={dashboard.title[lang]}
      description={dashboard.description[lang]}
      image={dashboard.image?.[lang] ?? ""}
      buttonText={t("quick_access_dashboard") as string}
      onButtonClick={() => {
        window.location.href = "/dashboard";
      }}
    />
  );
};

export default DashboardSection;