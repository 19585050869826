// components/LanguageMenu.tsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configurations } from "../modules/Configurations";
import { RootState } from "../modules";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LocalStorageManager } from "../LocalStorageManager";

const LangButton = styled.button<{ selected: boolean }>`
  padding: 8px;
  font-size: 14px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
color: ${(props) => (props.selected ? "#111" : "#999")};  
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05); 
  }
`;


const LanguageMenu = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const getInitialLang = () => {
    const storedLang = LocalStorageManager.getLanguage(); // 'kr' or 'en'
    if (storedLang === "kr") return "kr";
    return "en";
  };

  const [currentLang, setCurrentLang] = useState(getInitialLang());

  // ✅ 언어 선택 시: Redux + i18n + localStorage 모두 변경
  const changeLanguage = (lang: string) => {
    setCurrentLang(lang);
    dispatch(Configurations.setLanguage(lang));
    LocalStorageManager.setLanguage(lang);
    i18n.changeLanguage(lang === "kr" ? "kr" : "en");
  };

  // ✅ 마운트 시 localStorage 기준으로 초기 언어 강제 설정
  useEffect(() => {
    const storedLang = LocalStorageManager.getLanguage();
    const i18nLang = storedLang === "kr" ? "kr" : "en";
    if (i18n.language !== i18nLang) {
      i18n.changeLanguage(i18nLang);
      dispatch(Configurations.setLanguage(storedLang));
    }
    setCurrentLang(storedLang);
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <LangButton
        selected={currentLang === "kr"}
        onClick={() => changeLanguage("kr")}
      >
        KR
      </LangButton>
      <LangButton
        selected={currentLang === "en"}
        onClick={() => changeLanguage("en")}
      >
        EN
      </LangButton>
    </div>
  );
};

export default LanguageMenu;
