import React from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import OutlineButton from "../home/OutlineButton"; 
import H2 from "../../styledcomponents/text/H2";
import B2 from "../../styledcomponents/text/B2";
import Image from "../../styledcomponents/Image";
import { useTranslation } from "react-i18next";


interface MainFeatureSectionProps {
  title: string;
  description: string;
  image: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const MainFeatureSection = ({ title, description, image, buttonText, onButtonClick }: MainFeatureSectionProps) => {
  const isMultiline = typeof description === "string" && description.includes("\n");

  return (
    <FlexBox
      flexDirection="column"
      width="100%"
      alignItems="center"
      background="#FFF"
      padding={"144px 20px"}
    >
      {/* 공통 래퍼: 텍스트/버튼/이미지 모두 이 안에서 좌측 정렬 */}
      <FlexBox
        flexDirection="column"
        width="100%"
        maxWidth="1200px"
        alignItems="flex-start"
        padding={"0 24px"}
      >
        <H2 reactive>{title}</H2>

        <B2
          reactive
          marginTop="40px"
          marginBottom="40px"
        >
          {description}
        </B2>

        {buttonText && (
          <OutlineButton
            label={buttonText}
            onClick={onButtonClick || (() => {})}
          />
        )}

        <Image
          marginTop="60px"
          width="100%"
          borderRadius="20px"
          src={`/${image}`}
          alt="feature-section-img"
        />
      </FlexBox>
    </FlexBox>
  );
};

export default MainFeatureSection;