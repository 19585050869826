import React from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import { useTranslation } from "react-i18next";
import useMobile from "../../uses/UseMobile";

const reviewLogos = [
  "assets/images/daleumLogo.png",
  "assets/images/opusLogo.png",
  "assets/images/seoulbizLogo.png"
];

const ReviewCards = () => {
  const { t } = useTranslation();
  const reviews = t("reviews", { returnObjects: true });
  const reviewsArray = Array.isArray(reviews) ? reviews : [];
  const isMobile = useMobile();

  const mergedReviews = reviewsArray.map((review, index) => ({
    ...review,
    logo: reviewLogos[index] || "assets/images/defaultLogo.png"
  }));

  return (
    <FlexBox
      width="100%"
      maxWidth="1200px"
      flexWrap="wrap"
      justifyContent="center"
      style={{ gap: "24px" }}
      marginTop="60px"
    >
      {mergedReviews.map((review, index) => (
        <FlexBox
          key={index}
          flexDirection="column"
          justifyContent="space-between"
            alignItems="stretch"
          background="#FFF"
          borderRadius="12px"
          boxShadow="0 4px 10px rgba(0,0,0,0.05)"
          width="343px"
          height="337px"
          padding="24px"
        >
    
          <div style={{ width: "100%" }}> 
          <div
      style={{
        color: "#000",
        fontSize: isMobile ? "12px" : "16px",
        fontStyle: "normal" ,
        fontWeight: isMobile ? 400 : 700,
        lineHeight: isMobile ? "150%" : "160%",
        letterSpacing: isMobile ? "-0.12px" : "-0.16px",
        marginBottom: "24px",
        textAlign: "left"
      }}
    >
      {review.title}
    </div>
    <div
      style={{
        fontSize: isMobile ? "12px" : "14px",
        fontStyle:  "normal" ,
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: isMobile ? "-0.12px" : "-0.14px",
        textAlign: "left",
        whiteSpace: "pre-line",
      }}
    >
      {review.content}
    </div>
</div>
          <FlexBox justifyContent="flex-end">
            <img
              src={process.env.PUBLIC_URL + "/" + review.logo}
              alt={`${review.title} logo`}
              width={100}
              height={50}
              style={{ objectFit: "contain" }}
            />
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

export default ReviewCards;