import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const B1 = styled(Text)`
  color: #6B6B79;
  font-family: var(--Font-Family-Head, Pretendard);
  font-size: ${({ reactive }) => reactive ? reactiveValueInContent("24px", "20px") : "24px"};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.24px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
`;

export default B1;