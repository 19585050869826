import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import FlexBox from "../../styledcomponents/FlexBox";
import Text from "../../styledcomponents/Text";
import NewH2 from "../../styledcomponents/text/NewH2";
import NewH5 from "../../styledcomponents/text/NewH5"
import useMobile from "../../uses/UseMobile";
import { reactiveValueInContent } from "../../utils/StyleUtils";
import { message } from "antd";

const Contact = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_89yduqk",
        "template_q9rh1y8",
        e.currentTarget,
        "EwJIZEx9nJV8gbo3I"
      )
      .then(() => {
        message.success(t("contact_success_message"));
      }, (error) => {
        console.error(error);
        message.error(t("contact_error_message"));
      });

    e.currentTarget.reset();
  };

  return (
    <FlexBox
      justifyContent="center"
      alignItems="center"
      padding="100px 16px"
      width="100%"
      minWidth="343px"
      background="#101827"
    >
      <FlexBox
        width="100%"
        maxWidth="1200px"
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {/* 좌측 텍스트 */}
        <FlexBox
          flexDirection="column"
          style={{ flex: 1 }}
          minWidth="327px"
          maxWidth="500px"
          color="#FFF"
        >
          <NewH2 textAlign="left" style={{ color: "#FFF" }}>
  {t("contactForm.title")}
</NewH2>
          <Text
            color="#FFF"
            marginTop="16px"
            fontSize={reactiveValueInContent("18px", "14px")}
            whiteSpace="pre-line"
          >
            {t("contactForm.description") as string}
          </Text>
        </FlexBox>

        {/* 우측 폼 */}
        <form
          onSubmit={sendEmail}
          method="POST"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            minWidth: "327px",
            maxWidth: "700px",
            padding: "40px 24px",
            background: "#1E232E",
            borderRadius: "12px",
            flex: 1,
            marginTop: isMobile ? "40px" : "0"
          }}
        >
          <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", width: "100%", gap: "16px" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label
  style={{
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "8px",
    textAlign: "left", // ✅ 오직 텍스트만 왼쪽 정렬
    width: "100%" // ✅ 줄어들지 않게 고정
  }}
>
    {t("contactForm.nameLabel")}
  </label>
  <input
    type="text"
    name="from_name"
    placeholder={t("contactForm.namePlaceholder") as string}
    required
    className="rounded bg-white text-black px-4 py-3 w-full"
  />
</div>

            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <label
  style={{
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "8px",
    textAlign: "left", // ✅ 오직 텍스트만 왼쪽 정렬
    width: "100%" // ✅ 줄어들지 않게 고정
  }}
>
                {t("contactForm.emailLabel")}
              </label>
              <input
                type="email"
                name="reply_to"
                placeholder={t("contactForm.emailPlaceholder") as string}
                required
                className="rounded bg-white text-black px-4 py-3 w-full"
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <label
  style={{
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "8px",
    textAlign: "left", // ✅ 오직 텍스트만 왼쪽 정렬
    width: "100%" // ✅ 줄어들지 않게 고정
  }}
>
              {t("contactForm.messageLabel")}
            </label>
            <textarea
              name="message"
              rows={5}
              placeholder={t("contactForm.messagePlaceholder") as string}
              required
              className="rounded bg-white text-black px-4 py-3 w-full"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
  <button
    type="submit"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      padding: "10px 12px 10px 16px",
      width: "94px",
      height: "42px",
      backgroundColor: "#101CD7",
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
    }}
  >
    <NewH5 textAlign="center" style={{ color: "#FFF" }}>
      {t("contactForm.submit")}
    </NewH5>
    <img
      src="/assets/icons/arrowRight.svg"
      alt="arrow"
      width={16}
      height={16}
    />
  </button>
</div>
        </form>
      </FlexBox>
    </FlexBox>
  );
};

export default Contact;