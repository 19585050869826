import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewP1 = styled(Text)`
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
  color: #000;

    @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
`;

export default NewP1;