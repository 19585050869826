// components/common/OutlineButton.tsx
import React from "react";
import styled from "styled-components";
import useMobile from "../../uses/UseMobile";

const StyledButton = styled.button<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px 10px 18px;
  min-width: 109px;
  height: ${({ isMobile }) => (isMobile ? "40px" : "42px")};
  border: 1px solid #333;
  border-radius: 4px;
  background: #fff;
  color: #333;
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: ${({ isMobile }) => (isMobile ? "-0.14px" : "-0.16px")};
  cursor: pointer;

  span {
    display: inline-block;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

const OutlineButton = ({ label, onClick }: { label: string; onClick: () => void }) => {
  const isMobile = useMobile();

  return (
    <StyledButton isMobile={isMobile} onClick={onClick}>
      <span>{label}</span>
      <img src="/assets/icons/btn.svg" alt="arrow icon" />
    </StyledButton>
  );
};

export default OutlineButton;