import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../modules";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import Text from "../styledcomponents/Text";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import useTablet from "../uses/UseTablet";
import styled from "styled-components";
import { Colors } from "../styledcomponents/Styles";

const PolicyLink = styled.span`
  color: #606060;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: #333;
  }
`;

const FooterTitle = styled(Text)`
  color: #808080;
  font-weight: 600;
  font-size: 14px;
`;

const FooterDescription = styled(Text)`
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  word-break: break-word;
`;

const CommonFooter = () => {
  const isTablet = useTablet();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);

  return (
    <FlexBox width="100%" backgroundColor="#F0F1F5" borderTop={`1px solid ${Colors.GRAY_3}`}>
      <FlexBox
        flexDirection={isTablet ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ gap: isTablet ? "24px" : "0", padding: isTablet ? "48px 24px" : "48px 64px", width: "100%", maxWidth: "1920px", margin: "0 auto" }}
      >
        {/* Left Section - Logo & Links */}
        <FlexBox flexDirection="column" style={{ gap: "50px", maxWidth: isTablet ? undefined : "40%" }}>
          <FlexBox flexDirection="column" style={{ gap: "12px" }}>
            <Image
              src="/assets/images/logoGray.svg"
              alt="Thirdworks Logo"
              width="194px"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
            <Text fontSize="12px" color="#999" lineHeight="16.8px" style={{ letterSpacing: "-0.12px" }}>
              © 2025 THIRDWORKS Co. All Rights Reserved.
            </Text>
          </FlexBox>

          <FlexBox
            flexWrap="wrap"
            style={{ gap: isTablet ? "8px 20px" : "0 20px" }}
            alignItems="flex-start"
          >
            <PolicyLink onClick={() => window.open("/terms-of-service")}>{t("terms_of_service")}</PolicyLink>
            <PolicyLink onClick={() => window.open("/privacy-policy")}>{t("privacy_policy")}</PolicyLink>
            <PolicyLink onClick={() => window.open("/copyright")}>{t("copyright_policy")}</PolicyLink>
            <PolicyLink onClick={() => window.open("/email-policy")}>{t("deny_unauthorized_collection_of_email_addresses")}</PolicyLink>
          </FlexBox>
        </FlexBox>

        {/* Right Section - Company Info */}
        <FlexBox flexDirection="column" style={{ gap: "10px", maxWidth: isTablet ? undefined : "55%" }}>
          <FlexBox flexWrap="wrap" style={{ gap: isTablet ? "8px" : "16px" }}>
            <FooterTitle>{t("footer_company_name")}</FooterTitle>
            <FooterDescription>{t("third_works_company")}</FooterDescription>
          </FlexBox>

          <FlexBox flexWrap="wrap" style={{ gap: isTablet ? "8px" : "16px" }}>
            <FooterTitle>{t("representative")}</FooterTitle>
            <FooterDescription>{t("kim_minji")}</FooterDescription>
            {!isTablet && <VerticalDivider height="12px" backgroundColor="#808080" />}
            <FooterTitle>{t("email")}</FooterTitle>
            <FooterDescription>business@thirdworks.co.kr</FooterDescription>
          </FlexBox>

          <FlexBox flexWrap="wrap" style={{ gap: isTablet ? "8px" : "16px" }}>
            <FooterTitle>{t("business_registration_number")}</FooterTitle>
            <FooterDescription>784-87-02728</FooterDescription>
            {!isTablet && <VerticalDivider height="12px" backgroundColor="#808080" />}
            <FooterTitle>{t("e_commerce_registration_number_label")}</FooterTitle>
            <FooterDescription>제2023-성남분당A-0171호</FooterDescription>
            {!isTablet && <VerticalDivider height="12px" backgroundColor="#808080" />}
            <FooterTitle>{t("cpo")}</FooterTitle>
            <FooterDescription>{t("kim_minji")}</FooterDescription>
          </FlexBox>

          <FlexBox flexWrap="wrap" style={{ gap: isTablet ? "8px" : "16px" }}>
            <FooterTitle>{t("address_label")}</FooterTitle>
            <FooterDescription>{t("address_detail")}</FooterDescription>
            {!isTablet && <VerticalDivider height="12px" backgroundColor="#808080" />}
            <FooterTitle>{t("phone_label")}</FooterTitle>
            <FooterDescription>010-2476-9700</FooterDescription>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CommonFooter;
