import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "lucide-react";
import { FaqData } from "../home/FaqData";
import FlexBox from "../../styledcomponents/FlexBox";
import NewP1 from "../../styledcomponents/text/NewP1";
import NewH2 from "../../styledcomponents/text/NewH2";
import NewH5 from "../../styledcomponents/text/NewH5";
import useMobile from "../../uses/UseMobile";
import { reactiveValueInContent } from "../../utils/StyleUtils";

const FAQ = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="101px 16px" 
      width="100%"
      background="#FFF"
    >
      <FlexBox
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "flex-start"}
        justifyContent="space-between"
        width="100%"
        maxWidth="1200px"
        style={{ gap: reactiveValueInContent("40px", "24px") }}
      >
        {/* 좌측 타이틀 */}
        <FlexBox
          width="159px"
          minWidth="159px"
          height="42px"
          minHeight="42px"
        >
          <NewH2 textAlign={isMobile ? "center" : "left"}>
            {t("faqTitle")}
          </NewH2>
        </FlexBox>

        {/* 우측 FAQ 리스트 */}
        <FlexBox
          flexDirection="column"
          width="100%"
          maxWidth="800px"
          style={{ gap: reactiveValueInContent("24px", "18px") }}
        >
          {FaqData.map((faq, index) => {
            const isOpen = openIndex === index;

            return (
              <div
                key={index}
                onClick={() => setOpenIndex(isOpen ? null : index)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "327px",
                  width: "100%",
                  padding: "30px 24px",
                  gap: "24px",
                  alignItems: isOpen ? "flex-start" : "center",
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  background: isOpen ? "#DEE0E5" : "#F0F2F7",
                  transition: "all 0.3s",
                  cursor: "pointer",
                }}
              >
                <FlexBox justifyContent="space-between" alignItems="center" width="100%">
                  <NewH5 textAlign="left">
                    {t(faq.questionKey)}
                  </NewH5>
                  <ChevronDown
  style={{
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  }}
/>
                </FlexBox>

                {isOpen && (
                   <NewP1
                   textAlign="left"
                   whiteSpace="pre-line"
                   style={{
                     fontSize: reactiveValueInContent("16px", "14px"),
                     lineHeight: reactiveValueInContent("150%", "140%"),
                     letterSpacing: reactiveValueInContent("-0.16px", "-0.14px"),
                   }}
                 >
                   {t(faq.answerKey)}
                 </NewP1>
                )}
              </div>
            );
          })}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default FAQ;