/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
const KEY_ACCESS_TOKEN = "access-token"
const KEY_USER_ID = "user-id";
const KEY_LANGUAGE = "language"
const KEY_EXPOSED_HS_CODE_UNIT_INFO = "exposed_hscode_unit_info"

export class LocalStorageManager {
    static setAccessToken(token: string) {
        localStorage.setItem(KEY_ACCESS_TOKEN, token);
    }

    static getAccessToken(): string | null {
        return localStorage.getItem(KEY_ACCESS_TOKEN);
    }

    static removeAccessToken() {
        localStorage.removeItem(KEY_ACCESS_TOKEN)
    }

    static saveUserId(userId: string) {
        localStorage.setItem(KEY_USER_ID, userId);
    }

    static getUserId(): string | null {
        return localStorage.getItem(KEY_USER_ID);
    }

    static removeUserId() {
        localStorage.removeItem(KEY_USER_ID)
    }

    static getLanguage(): string {
        const currentLanguage = localStorage.getItem(KEY_LANGUAGE) || "en"
        if (currentLanguage === "ko") {
            return "kr"
        }
        return currentLanguage
    }

    static setUserName(username: string): void {
        localStorage.setItem("username", username);
      }
      
      static getUserName(): string | null {
        return localStorage.getItem("username");
      }
      
    static setLanguage(language: string) {
        localStorage.setItem(KEY_LANGUAGE, language)
    }

    static setExposedHsCodeUnitInfo(timestamp: string) {
        localStorage.setItem(KEY_EXPOSED_HS_CODE_UNIT_INFO, timestamp)
    }

    static getExposedHsCodeUnitInfo(): string {
        return localStorage.getItem(KEY_EXPOSED_HS_CODE_UNIT_INFO) || '0'
    }
}