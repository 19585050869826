// components/UserDropdownMenu.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Image from "../styledcomponents/Image";
import { LocalStorageManager } from "../LocalStorageManager";
import { Users } from "../modules/Users";
import ReactGA from "react-ga4";
import { Logger } from "../log/Logger";

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 210px;
  gap: 10px;
  background: #fff;
  border-radius: 0 0 12px 12px;
  box-shadow:
    0px 120px 34px rgba(0, 0, 0, 0.00),
    0px 77px 31px rgba(0, 0, 0, 0.01),
    0px 43px 26px rgba(0, 0, 0, 0.03),
    0px 19px 19px rgba(0, 0, 0, 0.05),
    0px 5px 11px rgba(0, 0, 0, 0.06);
  z-index: 100;
`;

const MenuItem = styled.div`
  display: flex;
  padding: 16px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--White, #FFF);
  font-size: 16px;
  font-weight: 500;
  color: #111;
  cursor: pointer;

  &:hover {
    background: #F7F7F7;
  }
`;

const UserDropdownMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!open);
  const closeDropdown = () => setOpen(false);

  const handleLogout = () => {
    ReactGA.event({ category: "pc_app_bar", action: `logout` });
    Logger.info("UserDropdownMenu", `logout. userId: ${LocalStorageManager.getUserId()}`);
    dispatch(Users.logout());
    navigate("/");
  };

  return (
    <DropdownWrapper onClick={toggleDropdown}>
      <Image width="24px" height="24px" src="/assets/icons/userCircle.svg" alt="user-icon" />
      <span style={{ fontWeight: 600, color: "#111" }}>
        {LocalStorageManager.getUserName()}
        {i18n.language === "kr" ? "님" : ""}
      </span>

      {open && (
        <DropdownMenu onMouseLeave={closeDropdown}>
        <MenuItem onClick={() => navigate("/my?index=0")}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Image
            src="/assets/icons/userCircle.svg"
            alt="user-icon"
            width="20px"
            height="20px"
          />
          {t("myPage")}
          </div>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <Image
      src="/assets/icons/logout.svg"
      alt="logout-icon"
      width="20px"
      height="20px"
    />
    {t("logout")}
  </div>
</MenuItem>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default UserDropdownMenu;