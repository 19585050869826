type FAQ = {
    questionKey: string;
    answerKey: string;
  };
  
  export const FaqData: FAQ[] = [
    {
      questionKey: "faq.question1",
      answerKey: "faq.answer1",
    },
    {
      questionKey: "faq.question2",
      answerKey: "faq.answer2",
    },
    {
      questionKey: "faq.question3",
      answerKey: "faq.answer3",
    },
    {
      questionKey: "faq.question4",
      answerKey: "faq.answer4",
    },
    // {
    //   questionKey: "faq.question5",
    //   answerKey: "faq.answer5",
    // },
    // {
    //     questionKey: "faq.question6",
    //     answerKey: "faq.answer6",
    //   },
      {
        questionKey: "faq.question7",
        answerKey: "faq.answer7",
      },
      // {
      //   questionKey: "faq.question8",
      //   answerKey: "faq.answer8",
      // },
  ];