import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import {reactiveValueInContent} from "../../utils/StyleUtils";

/**
 * @author Chaerin on 2025. 04. 03..
 */
const NewH6 = styled(Text)`
  font-size: ${({ reactive }) => reactive ? reactiveValueInContent("16px", "14px") : "16px"};
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
`;
export default NewH6;