import React from "react";
import { useTranslation } from "react-i18next";
import { featureSections } from "./FeatureSections";
import SolutionFeatureSection from "./SolutionFeatureSection";

const SolutionSection = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language as "kr" | "en";

  const solutionData = featureSections.find((section) => section.id === "solution");

  if (!solutionData || !solutionData.images) return null;

  return (
    <SolutionFeatureSection
      title={solutionData.title[lang]}
      description={solutionData.description[lang]}
      images={solutionData.images}
    />
  );
};

export default SolutionSection;