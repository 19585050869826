/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";
import {RegexUtils} from "../constants/RegexUtils";
import {UsersAPI} from "../network/UsersAPI";
import {SignInRequestBody} from "../model/SignInRequestBody";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors, Dimensions} from "../styledcomponents/Styles";
import NewH2 from "../styledcomponents/text/NewH2";
import NewH6 from "../styledcomponents/text/NewH6";
import Button from "../styledcomponents/Button";
import {ReactiveContentContainer, RootContainer} from "../styledcomponents/Containers";
import NewP1 from "../styledcomponents/text/NewP1";
import Input from "../styledcomponents/Input";
import Image from "../styledcomponents/Image";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import styled from "styled-components";
import Text from "../styledcomponents/Text";
import NewButton from "../styledcomponents/NewButton";
import {RootState} from "../modules";
import SignUpOptionsDialog from "../dialog/SignUpOptionsDialog";
import HorizontalDivider from "styledcomponents/HorizontalDivider";
import NewP3 from "../styledcomponents/text/NewP3";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {GoogleLoginButton} from "../components/GoogleLoginButton";
import {ResponseCodes} from "../network/ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Users} from "../modules/Users";


const lockClosePath = require(`assets/images/ic-lock-close.svg`).default
const lockOpenPath = require(`assets/images/ic-lock-open.svg`).default

const InputErrorText = styled(Text)`
    margin-top: 6px;
    font-size: 12px;
    text-align: left;
    color: ${Colors.INPUT_ERROR};
`

const NewLoginFragment = () => {

    const clientId = "985325558274-60qkcbhcns7vqegfbt72ll3getlkokir.apps.googleusercontent.com"
    const componentName = "NewLoginFragment"
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [password, setPassword] = useState("")
    const [lockClose, setLockClose] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const passwordRef = useRef<HTMLInputElement>(null)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const emailRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const isTablet = useTablet()
    const isMobile = useMobile()

    const [isSignUpOptionsDialogOpen, setIsSignUpOptionsDialogOpen] = useState(false)
    useEffect(() => {
        if (signedIn) {
            navigate("/")
        }
    }, [signedIn])

    function handleSignUpButtonClicked() {
        setIsSignUpOptionsDialogOpen(true)
    }

    function handleLoginButtonClicked() {
        ReactGA.event({
            category: "login",
            action: `login`
        });
        if (!RegexUtils.checkEmail(email)) {
            setEmailError(true)
            emailRef?.current?.focus()
            return;
        }
        UsersAPI.signIn(new SignInRequestBody(email, password))
            .then(async (response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                const result = response.data.data
                const accessToken = result?.accessToken
                const userId = result?.userId
                if (responseCode.isSuccess()) {
                    if (!userId || !accessToken) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }
                    LocalStorageManager.setAccessToken(accessToken)
                    const user = await UsersAPI.getUserOrNull()
                    if (!user) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }
                    dispatch(Users.loginSuccess(
                        componentName,
                        user,
                        accessToken
                    ));

                    const username = user?.name || user?.userId || "사용자";
                 
                    LocalStorageManager.setUserName(username);
                    
                // // ✅ 무조건 로컬로 강제 설정
                // const landingUrl = 'http://localhost:3000';
                    
                // window.location.href = `${landingUrl}?token=${encodedToken}&username=${encodedName}`;
                navigate("/");
                } else if (responseCode.isNotMatchingPassword()) {
                    alert(t('alert_password_is_incorrect'))
                } else if (responseCode.isNotFoundUserId()) {
                    alert(t('alert_email_is_not_registered'))
                } else {
                    alert(t('alert_failed_to_log_in'))
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    return (<RootContainer
        backgroundColor={Colors.GRAY_0}
        minHeight={"100%"}>
        <ReactiveContentContainer
            flexDirection={isTablet ? "column" : "row"}
            paddingTop={"80px"}
            paddingBottom={"80px"}
            alignItems={"center"}
            paddingLeft={isMobile ? "20px" : "0px"}
            paddingRight={isMobile ? "20px" : "0px"}
            justifyContent={"space-around"}>
            {!isMobile &&
                <FlexBox
                    flexDirection={"column"}>
                    <NewH2
                        color={Colors.GRAY_8}>{t('login_title')}</NewH2>
                    <NewH6
                        marginTop={"16px"}
                        textAlign={isTablet ? "center" : "left"}
                        lineHeight={"250%"}
                        color={Colors.GRAY_7}>{t('login_subtitle')}</NewH6>
                    {!isTablet && <Button
                        marginTop={"16px"}
                        padding={"14px 24px 14px 24px"}
                        border={Dimensions.RADIUS_MEDIUM}
                        backgroundColor={"#0B18E6"}
                        fontSize={"16px"}
                        fontWeight={700}
                        lineHeight={"150%"}
                        onClick={() => alert(t('alert_coming_soon'))}>
                        {t('consulting_inquiry')}
                    </Button>}
                </FlexBox>}
            <FlexBox
                marginTop={isTablet ? "40px" : "0px"}
                backgroundColor={Colors.WHITE}
                maxWidth={"420px"}
                width={isMobile ? "100%" : "420px"}
                paddingTop={"24px"}
                paddingBottom={"24px"}
                paddingLeft={"32px"}
                paddingRight={"32px"}
                border={`1px solid ${Colors.GRAY_2}`}
                borderRadius={"24px"}
                boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05);"}
                flexDirection={"column"}
                onChange={(e) => {
                    if (emailError) {
                        setEmailError(false)
                    }
                    if (passwordError) {
                        setPasswordError(false)
                    }
                }}>
                <NewH2
                    marginTop={"12px"}
                    color={Colors.GRAY_8}>{t('do_login')}</NewH2>
                <FlexBox
                    marginTop={"16px"}>
                    <NewH6
                        color={Colors.GRAY_7}
                    >{t('no_account')}</NewH6>
                    <NewH6
                        marginLeft={"8px"}
                        cursor={"pointer"}
                        onClick={() => {
                            ReactGA.event({
                                category: "login",
                                action: `signup`,
                                label: "click signup",
                            });
                            handleSignUpButtonClicked()
                        }}
                        color={Colors.MAIN}>{t('free_sign_up')}</NewH6>
                </FlexBox>
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"24px"}>{t('business_email')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={emailRef}
                        error={emailError}
                        value={email}
                        type="text"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleLoginButtonClicked()
                            }
                        }}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    {email && <Image
                        width={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        src={require("assets/images/ic-close-circle.svg").default}
                        cursor={"pointer"}
                        onClick={() => {
                            setEmail("")
                        }}
                        alt="email-clear-button"/>}
                </FlexBox>
                {emailError && <InputErrorText>{t('invalid_email_format')}</InputErrorText>}
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"20px"}>{t('password')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={passwordRef}
                        error={passwordError}
                        value={password}
                        type={lockClose ? "text" : "password"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleLoginButtonClicked()
                            }
                        }}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Image
                        maxWidth={"24px"}
                        width={"24px"}
                        maxHeight={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        cursor={"pointer"}
                        src={lockClose ? lockOpenPath : lockClosePath}
                        onClick={() => {
                            setLockClose(!lockClose)
                        }}
                        alt="password-lock-icon"/>

                </FlexBox>
                {passwordError && <InputErrorText>{t('invalid_password_format')}</InputErrorText>}
                <NewP1
                    color={Colors.MAIN}
                    alignSelf={"flex-end"}
                    cursor={"pointer"}
                    onClick={() => {
                        ReactGA.event({
                            category: "login",
                            action: `find_pw`
                        });
                        navigate("/password-find")
                    }}
                    marginTop={"8px"}>{t('forget_password')}</NewP1>
                <NewButton
                    borderRadius={"12px"}
                    colorType={0}
                    marginTop={"24px"}
                    fontSize={"16px"}
                    width={"100%"}
                    height={"52px"}
                    marginBottom={"24px"}
                    onClick={() => handleLoginButtonClicked()}>
                    {t('login')}
                </NewButton>
                <FlexBox
                    alignItems={"center"}
                    marginTop={"16px"}>
                    <HorizontalDivider
                        width={"100%"}/>
                    <NewP3
                        minWidth={"30px"}
                        color={Colors.GRAY_5}
                        marginLeft={"16px"}
                        marginRight={"16px"}>{t('or')}</NewP3>
                    <HorizontalDivider
                        width={"100%"}/>

                </FlexBox>
                <GoogleOAuthProvider clientId={clientId}>
                    <GoogleLoginButton/>
                </GoogleOAuthProvider>
            </FlexBox>
        </ReactiveContentContainer>
        <SignUpOptionsDialog dialogProps={{
            isOpen: isSignUpOptionsDialogOpen,
            onClose: (positive) => {
                setIsSignUpOptionsDialogOpen(false)
            }
        }}/>
    </RootContainer>)
}
export default NewLoginFragment;