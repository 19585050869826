import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NewH6 from "../styledcomponents/text/NewH6";
import NewH7 from "../styledcomponents/text/NewH7";
interface DropdownItem {
  key: string;
  title: string;
  description: string;
  href: string;
  icon: string;
  badge?: string;
}

const DropdownMenu = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [translatedItems, setTranslatedItems] = useState<DropdownItem[]>([]);
  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    setTranslatedItems([
      {
        key: "import_trend",
        title: t("dropdown.import_trend.title"),
        description: t("dropdown.import_trend.description"),
        href: "/dashboard?index=0",
        icon: "/assets/icons/importIcon.svg",
      },
      {
        key: "supply_analysis",
        title: t("dropdown.supply_analysis.title"),
        description: t("dropdown.supply_analysis.description"),
        href: "/dashboard?index=1",
        icon: "/assets/icons/supplyIcon.svg",
      },
      {
        key: "market_intelligence",
        title: t("dropdown.market_intelligence.title"),
        description: t("dropdown.market_intelligence.description"),
        href: "#",
        icon: "/assets/icons/marketAIicon.svg",
        badge: t("dropdown.badge_coming_soon") as string,
      },
      {
        key: "custom_research",
        title: t("dropdown.custom_research.title"),
        description: t("dropdown.custom_research.description"),
        href: "#",
        icon: "/assets/icons/customizedMarketIcon.svg",
        badge: t("dropdown.badge_coming_soon") as string,
      },
    ]);
  }, [t, i18n.language]);

  const handleClick = (item: DropdownItem, e: React.MouseEvent) => {
    if (item.badge) {
      e.preventDefault();
      alert(t("alert.comingSoon"));
    } else {
      window.location.href = item.href;
    }
  };

  return (
    <div
      onMouseEnter={() => {
        clearTimeout(timeoutId);
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        timeoutId = setTimeout(() => setIsOpen(false), 300);
      }}
      style={{ position: "relative", display: "inline-block" }}
    >
      <button
        type="button"
        onClick={() => (window.location.href = "/dashboard")}
        style={{
          fontWeight: 600,
          fontSize: "16px",
          color: "#111",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
          background: "none",
          border: "none"
        }}
      >
        {t("dashboard")}
        <img src="/assets/icons/caretDown.svg" alt="dropdown" width={16} height={16} />
      </button>

      {isOpen && (
  <div
    style={{
      position: "absolute",
      top: "100%",
      left: 0,
      background: "#FFF",
      borderRadius: "12px",
      boxShadow: "0px 2px 40px rgba(0,0,0,0.05)",
      zIndex: 100,
      minWidth: i18n.language === "en" ? "400px" : "343px",
      display: "flex",
      flexDirection: "column",
      gap: i18n.language === "en" ? "8px" : "10px"
    }}
  >
    {translatedItems.map((item) => (
      <a
        key={item.key}
        href={item.href}
        onClick={(e) => handleClick(item, e)}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px", 
          padding: "16px 18px",
          borderRadius: "8px",
          textDecoration: "none",
          color: "#111",
          background: "#FFF",
          transition: "background 0.2s",
          alignSelf: "stretch" 
        }}
        onMouseEnter={(e) => (e.currentTarget.style.background = "#F5F5F5")}
        onMouseLeave={(e) => (e.currentTarget.style.background = "#FFF")}
      >
        {/* 아이콘 */}
        <div
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "4px",
            background: "#E6E7FC",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            padding: "4px"
          }}
        >
          <img src={item.icon} alt="icon" width={40} height={40} />
        </div>

        {/* 텍스트 영역 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            flex: "1 0 0",
            whiteSpace: "nowrap"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%"
            }}
          >
            <NewH6>{item.title}</NewH6>
            {item.badge && (
  <span
    style={{
      display: "flex",
      height: "22px",
      padding: "6px 8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "4px",
      background: "#E6E6E6", // var(--Gray-90)
      color: "#666",         // var(--Gray-40)
      textAlign: "center",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "-0.1px"
    }}
  >
    {item.badge}
  </span>
            )}
          </div>
          <NewH7>{item.description}</NewH7>
        </div>
      </a>
    ))}
  </div>
)}
    </div>
  );
};

export default DropdownMenu;

