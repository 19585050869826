import React from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import PartnerLogosCarousel from "./PartnerLogosCarousel";
import ReviewCards from "./ReviewCards";

const Partners = () => {
  return (
    <FlexBox
      flexDirection="column"
      width="100%"
      alignItems="center"
      padding="144px 20px"
      background="#FFF"
    >
      <PartnerLogosCarousel />
      <ReviewCards />
    </FlexBox>
  );
};

export default Partners;