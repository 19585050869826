import styled from "styled-components";
import { Sizes } from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";

// 1. 전체 슬라이드 레이아웃
export const SlideWrapper = styled(FlexBox)`
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
  padding-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: clamp(40px, 4vw, 286px);

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 0;
  }

  @media screen and ${Sizes.MEDIA_QUERY_TABLET} {
    flex-direction: column;
    padding-bottom: 60px;
    gap: 0;
  }

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    align-items: center;
    justify-content: center;
  }
`;

// 2. 텍스트 영역
export const TextBox = styled(FlexBox)`
  max-width: 700px;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding-left: clamp(40px, 9vw, 180px);

  @media screen and ${Sizes.MEDIA_QUERY_TABLET} {
    padding-left: 16px;
    padding-right: 16px;
  }

@media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
  align-items: center;
  text-align: center;

  button {
    align-self: center !important;
  }
}

`;

// 3. 애니메이션 영역
export const AnimationBox = styled(FlexBox)`
  width: 900px;
  height: 900px;
  flex-shrink: 0;

  @media screen and (max-width: 1280px) and (min-width: 1100px) {
    width: 640px;
    height: 640px;
    margin-top: 30px;
  }

  @media screen and ${Sizes.MEDIA_QUERY_TABLET} {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

@media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}
`;