import styled from "styled-components";
import Text from "../Text";
import { Sizes } from "../Styles";
import {reactiveValueInContent} from "../../utils/StyleUtils";

/**
 * @author Chaerin on 2025. 04. 03..
 */
const NewH7 = styled(Text)`
  color: #616161;
  font-size: ${({ reactive }) => reactive ? reactiveValueInContent("14px", "12px") : "14px"};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;

  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 12px;
    letter-spacing: -0.12px;
  }
`;
export default NewH7;